import { Route, Routes } from "react-router-dom";

import About from "./pages/about/About";
import Blog from "./pages/blog/Blog";
import Contact from "./pages/contact/Contact";
import EntryTestResults from "./pages/entrytestresults";
import Footer from "./layouts/Footer";
// layouts
import Header from "./layouts/Header";
// components
import Home from "./pages/home/Home";
// hooks
import { ScrollToTop } from "./hooks/useScrollToTop";

function App() {
  return (
    <>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/blog"} element={<Blog />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/entrytestresults"} element={<EntryTestResults />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
