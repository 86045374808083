import React, { useState } from "react";

import BreadCrumbArea from "../../layouts/BreadCrumbArea";
import kankorData from "./data.json";
import useTitle from "../../hooks/useTitle";

const EntryTestResults = () => {
  useTitle("Entry Test Results - 1404");

  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [searched, setSearched] = useState(false);

  const onSearch = () => {
    if (name === "" && fatherName === "") {
      setData([]);
      return;
    }
    const filteredData = kankorData.filter((item) => {
      return (
        item.FullName.toLowerCase().includes(name.toLowerCase()) &&
        item.FatherName.toLowerCase().includes(fatherName.toLowerCase())
      );
    });
    setData(filteredData);
    setSearched(true);
  };

  return (
    <>
      <BreadCrumbArea
        title={"Entry Test Results"}
        subTitle={"Entry Test Results"}
        image="url(/assets/img/bg/breadcrumb-1.jpg)"
      />
      <div className="pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center mb-50 wow fadeInDown animated d-flex justify-content-center flex-column"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h2>
                  نتایج امتحان متقاضیان امتحان کانکور متفرقه سمستر بهاری سال
                  1404
                </h2>
                <center>
                  <hr
                    className="w-50 bg-black"
                    style={{ height: "1px", border: "none" }}
                  />
                </center>
              </div>
            </div>
          </div>

          <div dir="rtl" className="d-flex justify-content-center">
            <div className="row w-100">
              <div className="col-lg-5">
                <div className="contact-field p-relative c-subject mb-20">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="اسم"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ paddingRight: "15px" }}
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="contact-field p-relative c-subject mb-20">
                  <input
                    type="text"
                    id="fatherName"
                    name="fatherName"
                    placeholder="ولد"
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    style={{ paddingRight: "15px" }}
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="text-center">
                  <button
                    className="btn ss-btn w-100"
                    data-animation="fadeInRight"
                    data-delay=".8s"
                    style={{ borderRadius: "15px" }}
                    onClick={onSearch}
                  >
                    <i className="fal fa-search" />
                    جستجو
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center" dir="rtl">
            <div className="row w-100">
              {searched && (
                <div>
                  <table className="table table-bordered mb-40 mt-20">
                    <thead
                      className="text-white"
                      style={{ backgroundColor: "#125875" }}
                    >
                      <tr>
                        <th>اسم</th>
                        <th>ولد</th>
                        <th>پوهنحی</th>
                        <th>دیپارتمنت</th>
                        <th>نمره اخذ شده</th>
                        <th>نتیجه</th>
                        <th className="d-none d-md-table-cell">نوعیت کانکور</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>{item.FullName}</td>
                          <td>{item.FatherName}</td>
                          <td>{item.Faculty}</td>
                          <td>{item.Department}</td>
                          <td>{item.Score}</td>
                          <td>{item.Result}</td>
                          <td className="d-none d-md-table-cell">
                            {item.ExamType}
                          </td>
                        </tr>
                      ))}

                      {data.length === 0 &&
                        (name !== "" || fatherName !== "") && (
                          <tr className="my-5">
                            <td colSpan={6}>
                              <h4 className="text-center">نتیجه یی یافت نشد</h4>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EntryTestResults;
